import React from 'react'
import iosStore from '../images/app-ios-store.svg'
import googlePlay from '../images/app-google-play.svg'

export const AppleAppStoreLink = () => {
    return (
        <a
            href='https://apps.apple.com/au/app/zensim/id6499236479'
            target='_blank'
            rel='noreferrer'
            style={{display: 'inline-block'}}
        >
            <img src={iosStore} alt='ZenSim app on Apple App Store' width='150' />
        </a>
    )
}

export const GooglePlayStoreLink = () => {
    return (
        <a
            href='https://play.google.com/store/apps/details?id=com.zensim.zensim_app'
            target='_blank'
            rel='noreferrer'
            style={{display: 'inline-block'}}
        >
            <img src={googlePlay} alt='ZenSim app on Google Play' width='150' />
        </a>
    )
}
